import React from "react"
import Header from "../components/header";

const IndexPage = () => {
    return (
        <div>
            <Header />
            <p>Welcome to vefside.com</p>
        </div>
    );
};

export default IndexPage;